<template>
  <div id="fewo">
    <fewoheader activeTheme="hausastrid" activeFewo="Ferienwohnungen Haus Astrid"/>
    <main>
      <willkommen/>
      <fewos activeTheme="hausastrid" headline="Ferienwohnungen"/>
      <prices activeTheme="hausastrid" headline="Preise"/>
      <location activeTheme="hausastrid"/>
      <contact activeTheme="hausastrid" activeFewo="Ferienwohnungen Haus Astrid"/>
    </main>
    <fewofooter activeTheme="hausastrid"/>
  </div>
</template>

<script>
  import fewoheader from "~/components/page/header/main.vue";
  import fewofooter from "~/components/page/footer/main.vue";
  import willkommen from "../custom-components/willkommen.vue";
  import fewos from "~/components/content/fewos.vue";
  import prices from "~/components/content/prices.vue";
  import location from "~/components/content/location.vue";
  import contact from "~/components/content/contact.vue";

  export default {
    components: {
      fewoheader,
      fewofooter,
      willkommen,
      fewos,
      prices,
      location,
      contact
    },

    head() {
      return {
        title: "Ferienwohnung Haus Astrid",
        meta: [
          { name: "description", content: "Haus Astrid in Warnemünde - Unsere Ferienwohnungen sind 300m vom Strand und 5 Minuten vom Alten Strom entfernt. Jede Ferienwohnung ist modern in komfortabel eingerichtet." },
          { name: "msapplication-TileColor", content: "#ffffff" },
          { name: "msapplication-TileImage", content: "Ferienwohnung Haus Astrid" },
          { name: "theme-color", content: "#ffffff" }
        ],
        bodyAttrs: {
          id: 'hausastrid'
        }
      }
    }
  }
</script>
