<template>
    <b-container>
        <h1>Willkommen im Haus Astrid</h1>
        <b-row>
            <b-col cols="12" sm="6">
                <p>Das Haus Astrid liegt ruhig und zentral im Herzen von Warnemünde, etwa 250 m vom Strand entfernt. Der in den 1920er Jahren errichtete Altbau bietet vier hochwertige und komfortabel ausgestattete Ferienwohnungen, die erst kürzlich mit großem Aufwand renoviert wurden. Der Altbau begeistert mit klassischen stuckverzierten Decken, einem nach historischem Vorbild restaurierten Treppenhaus sowie liebevoll aufgearbeiteten Türen. Weiterhin verfügt das Haus Astrid über einen hauseigenen Billardraum und einen kleinen Garten.</p>
                <p><strong>Urlaub am breitesten Strand der Ostsee</strong></p>
                <p>Genießen Sie Ihren Urlaub im charmanten Ostseebad Warnemünde. Stöbern Sie in kleinen Boutiquen, kehren Sie in einer der vielen urigen Fischerkneipen ein, oder stürzen Sie sich in das bunte Treiben am Hafen. Neben Fischern, die Ihren Fang direkt vom Boot aus verkaufen, kann man dort große Kreuzfahrtschiffe, wie das Clubschiff Aida, beim Ein- und Auslaufen beobachten. Warnemünde bietet Ihnen auch ein vielfältiges kulturelles Rahmenprogramm: Theater, Kurhaus, Spielcasino, Kino sowie unzählige Restaurants und Cafes warten nur auf Ihren Besuch.</p>
                <p>Ganz gleich ob Sie allein, als Pärchen oder mit der ganzen Familie anreisen – wir bieten Ihnen die passende Unterkunft.</p>
                <p>Ihre Familie Schäfer</p>
            </b-col>
            <b-col cols="12" sm="6">
                <picture>
                    <source media="(min-width: 768px)" :data-srcset="require('~/assets/img/hausastrid/postkarte_HA_gedreht.png?size=405').src + ' 1x,' + require('~/assets/img/hausastrid/postkarte_HA_gedreht.png?size=810').src + ' 2x'"/>
                    <source media="(min-width: 320px)" :data-srcset="require('~/assets/img/hausastrid/postkarte_HA_gedreht.png?size=768').src + ' 1x, ' + require('~/assets/img/hausastrid/postkarte_HA_gedreht.png?resize').src + ' 2x'"/>
                    <img class="lazyload" :src="require('~/assets/img/hausastrid/postkarte_HA_gedreht.png?resize').placeholder" :data-src="require('~/assets/img/hausastrid/postkarte_HA_gedreht.png?size=405').src" alt="Ferienwohnung Haus Astrid"/>
                </picture>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Willkommen",
    }
</script>

<style scoped lang="scss">
    picture img {
        width: 100%;
    }
</style>